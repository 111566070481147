$(document).ready(function () {
  "use strict";

  $(".loader").delay(400).fadeOut();
  $(".animationload").delay(400).fadeOut("fast");

  /* =================================
  NAVBAR
  =================================== */
  jQuery(window).scroll(function () {
    var top = jQuery(document).scrollTop();
    var batas = jQuery(window).height();

    if (top > batas) {
      jQuery('.navbar-main').addClass('stiky');
    } else {
      jQuery('.navbar-main').removeClass('stiky');
    }
  });

  /* =================================
  BANNER ROTATOR IMAGE
  =================================== */
  $('#slides').superslides({
    //animation: "fade",
    play: 10000,
    slide_speed: 1000,
    pagination: true,
    hashchange: false,
    scrollable: true,
    inherit_height_from: '#slides',
  });

  /* =================================
  BACK TO TOP
  =================================== */
  // browser window scroll (in pixels) after which the "back to top" link is shown
  var offset = 300,
    //browser window scroll (in pixels) after which the "back to top" link opacity is reduced
    offset_opacity = 1200,
    //duration of the top scrolling animation (in ms)
    scroll_top_duration = 700,
    //grab the "back to top" link
    $back_to_top = $('.cd-top');

  //hide or show the "back to top" link
  $(window).scroll(function () {
    ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
    if ($(this).scrollTop() > offset_opacity) {
      $back_to_top.addClass('cd-fade-out');
    }
  });

  //smooth scroll to top
  $back_to_top.on('click', function (event) {
    event.preventDefault();
    $('body,html').animate({
      scrollTop: 0,
    }, scroll_top_duration
    );
  });

  /* =================================
  ISOTOPE
  =================================== */
  $(window).load(function () {
    // INITIALIZE ISOTOPE
    var $container = $('.grid-services');
    $container.isotope({
      filter: '*',
    });
    $('.portfolio_filter a').on('click', function () {
      $('.portfolio_filter .active').removeClass('active');
      $(this).addClass('active');

      var selector = $(this).attr('data-filter');
      $container.isotope({
        filter: selector,
        animationOptions: {
          duration: 500,
          animationEngine: "jquery"
        }
      });
      return false;
    });
  });

  /* =================================
  OWL
  =================================== */

  var caro = $("#caro");
  caro.owlCarousel({
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    loop: true,
  });

  var owl = $("#owl-testimony");
  owl.owlCarousel({
    autoplay: 5000,
    stopOnHover: true,
    margin: 30,
    items: 2,
    nav: true,
    navText: ["<span class='fa fa-chevron-left'></span>", "<span class='fa fa-chevron-right'></span>"],
    dots: true,
    loop: true,
    responsive: {
      0: {
        items: 1
      },
      1000: {
        items: 2
      }
    }
  });

  var testimony2 = $("#owl-testimony2");
  testimony2.owlCarousel({
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    navText: ["<span class='fa fa-chevron-left'></span>", "<span class='fa fa-chevron-right'></span>"],
    dots: true,
    loop: true
  });

  /* =================================
  FAQ
  =================================== */
  $(".panel").on("show.bs.collapse hide.bs.collapse", function (e) {
    if (e.type == 'show') {
      $(this).addClass('active');
    } else {
      $(this).removeClass('active');
    }
  });

  /* =================================
  MAGNIFIC POPUP
  =================================== */
  $('.popup-youtube, .popup-vimeo, .popup-gmaps').magnificPopup({
    disableOn: 700,
    type: 'iframe',
    mainClass: 'mfp-fade',
    removalDelay: 160,
    preloader: false,

    fixedContentPos: false
  });

  $('.grid-services').magnificPopup({
    delegate: 'a',
    type: 'image',
    tLoading: 'Loading image #%curr%...',
    mainClass: 'mfp-img-mobile',
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function (item) {
        return item.el.attr('title') + '';
      }
    }
  });


  /* =================================
  GOOGLE MAPS
  =================================== */

  function CustomZoomControl(controlDiv, map) {
    //grap the zoom elements from the DOM and insert them in the map
    var controlUIzoomIn = document.getElementById('cd-zoom-in'),
      controlUIzoomOut = document.getElementById('cd-zoom-out');
    controlDiv.appendChild(controlUIzoomIn);
    controlDiv.appendChild(controlUIzoomOut);

    // Setup the click event listeners and zoom-in or out according to the clicked element
    google.maps.event.addDomListener(controlUIzoomIn, 'click', function () {
      map.setZoom(map.getZoom() + 1)
    });
    google.maps.event.addDomListener(controlUIzoomOut, 'click', function () {
      map.setZoom(map.getZoom() - 1)
    });
  }

  if ($('#maps').length) {
    //set your google maps parameters
    var myLat = $('#maps').data('lat'),
      myLng = $('#maps').data('lng'),
      myMarkerx = $('#maps').data('marker');


    var latitude = myLat,
      longitude = myLng,
      markerx = myMarkerx,
      map_zoom = 14;

    //google map custom marker icon - .png fallback for IE11
    var is_internetExplorer11 = navigator.userAgent.toLowerCase().indexOf('trident') > -1;
    var marker_url = (is_internetExplorer11) ? markerx : markerx;

    //define the basic color of your map, plus a value for saturation and brightness
    var main_color = '#000000',
      saturation_value = -80,
      brightness_value = 5;

    //we define here the style of the map
    var style = [
      {
        //set saturation for the labels on the map
        elementType: "labels",
        stylers: [
          { saturation: saturation_value }
        ]
      },
      { //poi stands for point of interest - don't show these lables on the map
        featureType: "poi",
        elementType: "labels",
        stylers: [
          { visibility: "off" }
        ]
      },
      {
        //don't show highways lables on the map
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [
          { visibility: "off" }
        ]
      },
      {
        //don't show local road lables on the map
        featureType: "road.local",
        elementType: "labels.icon",
        stylers: [
          { visibility: "off" }
        ]
      },
      {
        //don't show arterial road lables on the map
        featureType: "road.arterial",
        elementType: "labels.icon",
        stylers: [
          { visibility: "off" }
        ]
      },
      {
        //don't show road lables on the map
        featureType: "road",
        elementType: "geometry.stroke",
        stylers: [
          { visibility: "off" }
        ]
      },
      //style different elements on the map
      {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.government",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.sport_complex",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.attraction",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "poi.business",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "transit",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "transit.station",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "landscape",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]

      },
      {
        featureType: "road",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      },
      {
        featureType: "water",
        elementType: "geometry",
        stylers: [
          { hue: main_color },
          { visibility: "on" },
          { lightness: brightness_value },
          { saturation: saturation_value }
        ]
      }
    ];

    //set google map options
    var map_options = {
      center: new google.maps.LatLng(latitude, longitude),
      zoom: map_zoom,
      panControl: false,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scrollwheel: false,
      styles: style,
    }
    //inizialize the map
    var map = new google.maps.Map(document.getElementById('maps'), map_options);
    //add a custom marker to the map
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map: map,
      visible: true,
      icon: marker_url,
    });

    var zoomControlDiv = document.createElement('div');
    var zoomControl = new CustomZoomControl(zoomControlDiv, map);

    //insert the zoom div on the top left of the map
    map.controls[google.maps.ControlPosition.LEFT_TOP].push(zoomControlDiv);
  }
});
